<template>
	<div>
		<!-- 首页固定区 -->
		<div class="topadimage"> 
			<img :src="$img(adList[0].adv_image)" v-if="adList[0].adv_image"/>
			<img :src="adList[0].adv_image_link" v-else/>
		</div>
		
		<div class="indexsearch">
		<div class="in-sousuo">
			<div class="sousuo-box">
				 <select v-model="select_cat_id" class="cate_select">
					 <option value="0">全部</option>
					 <option value="1">野生</option>
					 <option value="2">仿生</option>
					 <option value="3">种植</option>
				 </select>
				<input type="text"   v-model="keyword" @keyup.enter="search" maxlength="50" />
				<el-button class="serbtn" type="primary" size="small" @click="search">搜索</el-button>
			</div>
			<div class="hot-search-words" v-if="hotSearchWords.length">				 
				<ul>
					<li  @click="select_cat_id=1;search();">
						野生
					</li>
					<li  @click="select_cat_id=2;search();">
						仿生
					</li>
					<li  @click="select_cat_id=3;search();">
						种植
					</li>
					<li  @click="select_cat_id=25;search();">
						浙丽畲药
					</li>
				</ul>
			</div>
		</div>
		</div>
         <!-- 热门咨询 行业信息 -->
		<div class="index_news">
			<div class="index_news_inner">
				<div class="hot_news">
					<div class="ht_title">
						<div class="httleft">
							平台介绍
						</div>
						<div class="httright">
							<router-link  to="/cms/news-6">
							查看更多 >>
							</router-link>
						</div>
					</div>
					<ul class="ht_list">
						<li v-for="item in hotNewsList" :key="item.id">
							<div class="titleimgleft">
								<img :src="$img(item.img)"/>
							</div>
							<div class="titleconright">
								<div class="tcrtitle">
								 <router-link :to="'/cms/newsdetail-' + item.id" >
								   {{item.title}}
								 </router-link>
								</div>
								<div class="tcrcon">{{item.content}}</div>
								<div class="tcrtime">{{item.create_time}}</div>
							</div>
							
						</li>
					</ul>
				</div>
				<div class="indu_news">
					<div class="topimg">
						<img src="../../assets/images/index/index_innews_bg.png"/>
					</div>
					<ul>
						<li v-for="item in induNewsList" :key="item.id">
							<router-link  :to="'/cms/newsdetail-' + item.id">
							{{item.title}}
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- 热门咨询 行业信息 -->
		<div class="indexbigimg">
			<div class="ibiinner"> 
			   <div class="bimgtitle">全过程溯源及数字化评级技术</div>
			   <div class="bimgimgs">
				   <div class="biileft" v-for="(alitem,alindex) in adLeftList" :key="alitem.adv_id" v-if="alindex<1" @click="openbigimg(alitem.adv_image_big)">
					   <img :src="$img(alitem.adv_image)"/><br />{{alitem.adv_title}}
				   </div>
				   <div class="biiright">
					   <ul>
					       <li v-for="(aritem,arindex) in adRightList" :key="aritem.adv_id" v-if="arindex>=2"  @click="openbigimg(aritem.adv_image_big)"><img :src="$img(aritem.adv_image)"/><br />{{aritem.adv_title}}</li> 
					   </ul>
					   <ul>
						   <li v-for="(aritem,arindex) in adRightList" :key="aritem.adv_id" v-if="arindex<2"  @click="openbigimg(aritem.adv_image_big)"><img :src="$img(aritem.adv_image)"/><br />{{aritem.adv_title}}</li>
						    
					   </ul>
					   
				   </div>
			   </div>
			</div>
		</div>
		
		
		<div class="index-wrap"  :style="{ background: backgroundColor }" v-if="adList.length>0">
			
			<div class="index">
				<div class="banner">
					<el-carousel height="400px" arrow="hover" v-loading="loadingAd" @change="handleChange" ref="carousel">
						<el-carousel-item v-for="(item,index) in adList" :key="item.adv_id" v-if="index>0">
							<el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)"  v-if="item.adv_image"/>
							<el-image :src="item.adv_image_link" fit="cover" @click="$router.pushToTab(item.adv_url.url)" v-else/>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div> 
			   <div class="mcbox" >			           
					<div v-for="(item,index) in adList" :key="item.adv_id"  v-if="index>0" :class="curcourseindex==index-1?'mcciboxactive':'mccibox'" @click="change_sj_desc(index-1)">
						<p class="mccit1">
							{{item.title1}}
						</p>
						<p class="mccit2">
							{{item.title2}}
						</p>
			     </div>
			  </div>
		</div>
		
		
		<!-- 帮助中心 -->
		<div class="helplist">
			<div class="helpinner">
				<div class="helpleft">
					<div class="helpclass" v-for="(item, index) in helpList" :key="index" >
					    <p :class="index==helpclassindex?'helpclasscur':''" @click="changeHelpClass(index)">{{ item.class_name }}</p> 
					</div>
					<ul class="helpnews">
						<block v-for="(helpItem, helpIndex) in helpcurlist.child_list" :key="helpIndex" >
							<li  v-if="!helpItem.link_address"  @click="clickToHelpDetail(helpItem.id)">{{ helpItem.title }}<span>详情 >&nbsp;</span></li>
							<li  v-else  @click="clickJump(helpItem.link_address)">{{ helpItem.title }}<span>详情 >&nbsp;</span></li>
						</block>                      						
					</ul>
				</div>
				<div class="helpright">
					<div class="hlrtitle">热门品种</div>
					<div class="hlrimgs">
						<ul>							
							<li v-for="(abitem,abindex) in adbottom" :key="abitem.adv_id" v-if="abindex<6"  @click="opentardeurl(abitem.adv_url.url)"><img :src="$img(abitem.adv_image)"/></li>
						</ul>
					</div>
					<!--<div class="hlrbtn">
						<button @click="clickTocenter">前往交易中心 > </button>
						<button @click="clickTohall">前往交易大厅 > </button>
					</div>-->
				</div>
			</div>
		</div>
		 

			<!-- 楼层区 -->
			<div class="content-div">
				<div class="floor">
					<div v-for="(item, index) in floorList" :key="index" class="floor_item">
						<floor-style-1 v-if="item.block_name == 'floor-style-1'" :data="item" />
						<floor-style-2 v-if="item.block_name == 'floor-style-2'" :data="item" />
						<floor-style-3 v-if="item.block_name == 'floor-style-3'" :data="item" />
					</div>
				</div>
			</div>
			
			<!-- 浮层区 -->
			<div class="floatLayer-wrap" v-if="floatLayer.is_show_type">
				<div class="floatLayer">
					<div class="img-wrap">
						<img :src="$img(floatLayer.img_url)" @click="$router.pushToTab(floatLayer.link.url)" />
					</div>
					<i class="el-icon-circle-close" @click="closeFloat"></i>
				</div>
			</div>

			<!-- 悬浮搜索 -->
			<div class="fixed-box" :style="{ display: isShow ? 'block' : 'none' }">
				<div class="header-search">
					<el-row>
						<el-col :span="6">
							<router-link to="/" class="logo-wrap"><img :src="$img(siteInfo.logo)" /></router-link>
						</el-col>
						<el-col :span="13">
							<div class="in-sousuo">
								<div class="sousuo-box">
									<el-dropdown @command="handleCommand" trigger="click">
										<span class="el-dropdown-link">
											{{ searchTypeText }}
											<i class="el-icon-arrow-down"></i>
										</span>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item command="goods">商品</el-dropdown-item>
											<el-dropdown-item command="shop">店铺</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
									<input type="text" :placeholder="defaultSearchWords" v-model="keyword" @keyup.enter="search" maxlength="50" />
									<el-button type="primary" size="small" @click="search">搜索</el-button>
								</div>
							</div>
						</el-col>
						<el-col :span="5">
							<div class="cart-wrap">
								<router-link class="cart" to="/cart">
									<span>我的购物车</span>
									<el-badge v-if="cartCount" :value="cartCount" type="primary"><i class="iconfont icongouwuche"></i></el-badge>
									<i v-else class="iconfont icongouwuche"></i>
								</router-link>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<el-dialog  title="" :visible.sync="imgVisible" width="60%"   :lock-scroll="false" center>
				<div   class="xyContent"><img :src="attrimgsrc"/></div>
			</el-dialog>
		</div>
		
	</div>
	
</template>

		
<script>
import floorStyle1 from './components/floor-style-1.vue';
import floorStyle2 from './components/floor-style-2.vue';
import floorStyle3 from './components/floor-style-3.vue';
import index from './_index/index.js';

export default {
	name: 'index',
	components: { floorStyle1,floorStyle2,floorStyle3 },
	mixins: [index]
};
</script>

<style lang="scss" scoped>
@import './_index/index.scss';
</style>

<style lang="scss">
.count-down {
	span {
		display: inline-block;
		width: 22px;
		height: 22px;
		line-height: 22px;
		text-align: center;
		background: #383838;
		color: #ffffff;
		border-radius: 2px;
	}
}
.el-carousel__indicators{
	 
	top:260px;
}
.el-carousel__button{
	height:5px;
	width:40px;
	border-radius: 5px;
}
.mcbox{
	position: absolute;
	z-index: 999;
	height:80px;
	width:940px;
	padding-left:270px;
	background-color: #e1e7e9;
	opacity: 0.8;
	 
	top:2010px; 
	text-align:center;
}
.mccibox{
	display:block;
	float:left;
	width:220px;
	height:80px;
	cursor: pointer;
	background-color: #f0f0f0;
	text-align:center;
	 color:#000000;
	 
	.mccit1{
		 margin-top:15px;
	     font-weight: bolder;
		 font-size:15px; 
	}
	.mccit2{
		
	}
	
}
.mcciboxactive{
	display:block;
	float:left;
	width:220px;
	height:80px;
	cursor: pointer;
	background-color: #ffffff;
	text-align:center;
	color:#063bc6;
	.mccit1{
		margin-top:15px;
	     font-weight: bolder;
		 font-size:15px; 
	}
	.mccit2{
		
	}
	
}
.in-sousuo {
		width: 700px;
		padding-top: 20px;
		margin:auto; 
	 
		.sousuo-box {
			width: 100%;
			height: 46px;
			line-height:46px;
			border: 1px solid #61433c;
			box-sizing: border-box;
	        border-radius: 10px;
			.el-dropdown {
				padding: 0 10px;
				cursor: pointer;
	
				&::after {
					content: '';
					border-left: 1px solid #cfcfcf;
					margin-left: 5px;
				}
			}
	        .serbtn{
				background-color: #61433c;
				border-radius: 10px;
			    border: 3px solid #61433c;
				width:80px;
				height:45px;
				font-size:15px;
			}
			input {
				width: 380px;
				height: 22px;
				background: none;
				outline: none;
				border: none;
				margin: 4px;
			}
	
			button {
				border-radius: 0;
				float: right;
			}
		}
	
		.hot-search-words {
			width: 100%;
			height: 20px;
			margin-top: 10px;
			font-size: 14px;
	 
	
			ul {
				overflow: hidden;
				margin: 0;
				height: 21px;
				padding: 0;
				color: #111111; 
	            padding-left:80px; 
				li {
					cursor: pointer;
					list-style: none;
					float: left;
					margin-right: 15px;
	
					&:hover {
						color: $base-color;
					}
				}
			}
		}
	}
	.indexsearch{
		width: 100%;
		background-color: #ffffff !important;
		padding-top:10px;
	}
	.cate_select{
		width:60px;
		height:35px;
		margin-left:5px;
	}
</style>
